@use "mixins" as *
.sport,
.live,
.casinoButton,
.betslip,
.liveCasino,
.casino,
.slots
  > p
    flex: 1
    padding: 2px 0
    text-wrap: balance
    line-height: 13px
    @include flex
  &:active
    background: var(--menu_itemActiveBg)
    color: rgba(255,255,255, .8)

  &.active
    background: var(--menu_itemActiveBg)
    box-shadow: var(--menu_itemActiveShadow)
    color: #fff

.sport,
.live,
.casino,
.liveCasino,
.slots
  padding: 2px 4px 0
  position: relative
  min-width: calc((100% - 90px) / 4)
  color: rgb(var(--primaryColor))

  :global(.svg-icon)
    margin-bottom: 8px

    path, :global(.menu-live-bg)
      fill: var(--menu_itemIconFill)

  .navigateTo
    background-color: var(--menu_itemActiveBaseColor)
    mask-image: var(--icon-menu-double-arrow)
    transform: rotate(45deg)
    width: 10px
    height: 10px
    mask-size: 10px 10px
    position: absolute
    right: 0
    top: 6px
    transform-origin: 0 0

.live.active :global(.menu-live-bg)
  fill: var(--menu_liveActiveIcon)

.sport.active :global(.svg-icon) svg path
  fill: var(--menu_sportActiveIcon)

.liveCasino,
.slots
  &.active :global(.svg-icon) svg path
    fill: var(--menu_itemActiveBaseColor)

  .closeIframe
    right: 2px

.liveCasino
  :global(.svg-icon) svg
    width: 23px
    height: 18px

.slots :global(.svg-icon) svg
  width: 18px
  height: 18px

.betslip,
.casinoButton
  position: relative
  min-width: 84px
  justify-content: flex-start

  > div
    width: 84px
    height: 32px
    min-height: 32px
    display: inline-block
    vertical-align: middle
    position: relative

    :global(.menu-betslip-top-color)
      stop-color: var(--menu_betslipIconTopColor)

    :global(.menu-betslip-bottom-color)
      stop-color: var(--menu_betslipIconBottomColor)

    :global(.menu-betslip-top-color),
    :global(.menu-betslip-bottom-color)
      transition: all 0.5s

.betslip
  color: #fff

  .spinner
    position: absolute
    z-index: 5
    @include flex
    justify-content: center
    width: 100%
    height: 100%
    top: 0
    left: 0

    :global(.spinner-color)
      fill: #fff

    svg
      width: 50px
      height: 50px

  > div
    &.betslipHasErrorBtn
      :global(.menu-betslip-top-color)
        stop-color: #bf2226

      :global(.menu-betslip-bottom-color)
        stop-color: #ea3c40

    &:before,
    &:after
      content: ""
      background-color: var(--menu_betslipArrowsFill)
      mask-image: var(--icon-menu-double-arrow)
      width: 10px
      height: 10px
      top: 12px
      position: absolute
      transform: rotate(180deg)
      transition: all 0.2s

    &:before
      left: 7px

    &:after
      right: 7px

    > span:not(:global(.svg-icon))
      position: absolute
      left: 50%
      margin-left: -10px
      width: 20px
      height: 20px
      padding: 2px
      border-radius: 4px
      background: var(--menu_betslipCountBg)
      font-size: 12px
      @include flex
      justify-content: center
      font-weight: normal
      top: 6px
      box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.9)

      &.animate
        animation: puff-in-center 0.3s linear both
        -webkit-animation: puff-in-center 0.3s linear both

      @keyframes puff-in-center
        0%
          transform: scale(2)
          -webkit-transform: scale(2)
          opacity: 0.8
        100%
          transform: scale(1)
          -webkit-transform: scale(1)
          opacity: 1

  &.betSlipActive > div
    &:before,
    &:after
      transform: rotate(0)

.casinoButton
  color: var(--menu_itemActiveBaseColor)

  .providers,
  .search
    position: absolute
    left: 6px
    top: 9px
    @include flex

    svg
      width: 12px
      height: 14px

      path
        fill: var(--menu_casinoIconsFill)

  .search
    left: unset
    right: 5px

    svg
      width: 14px

  > div .arrow
    position: absolute
    left: 50%
    margin-left: -10px
    width: 20px
    height: 20px
    padding: 2px
    border-radius: 4px
    background: var(--menu_betslipCountBg)
    font-size: 12px
    @include flex
    justify-content: center
    font-weight: normal
    top: 8px
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.9)

    &:before
      content: ""
      background-color: var(--menu_itemActiveBaseColor)
      -webkit-mask-image: var(--icon-menu-double-arrow)
      mask-image: var(--icon-menu-double-arrow)
      width: 10px
      height: 10px
      top: 5px
      position: absolute
      transform: rotate(180deg)
      transition: all 0.2s

  &.casinoButtonActive
    .providers,
    .search
      svg
        path
          fill: var(--menu_itemActiveBaseColor)

    > div .arrow:before
      transform: rotate(0)
      background-color: var(--menu_casinoIconsFill)

.menuCasinoModal
  font-size: 13px
  :global(.modal)
    width: 100% !important
    height: auto !important
    max-height: 100%
    overflow: auto
    background: var(--menu_casinoModalBg)
    display: flex
    flex-direction: column
    border-radius: 8px 8px 0 0

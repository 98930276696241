@use "mixins" as *
.subHeader
  background: var(--subHeader_bg)
  border-bottom: 1px solid var(--subHeader_border)
  @include flex
  width: 100%
  padding: 0 3px
  nav
    overflow-x: auto
    @include flex
    padding: 4px 0
    gap: 6px

    &::-webkit-scrollbar
      display: none
    > a, > button
      min-width: 80px
      height: 40px
      border-radius: 4px
      padding: 0 12px
      @include flex
      justify-content: center
      color: rgb(var(--primaryColor))
      @include bold
      font-size: 14px
      white-space: nowrap
      background: transparent
      text-transform: capitalize
      position: relative
      flex: none

      :global(.svg-icon)
        margin-right: 8px
        path, :global(.menu-live-bg)
          fill: rgb(var(--primaryColor))

      &:active
        background: var(--subHeader_activeBg)
        color: #fff
        :global(.svg-icon)
          path, :global(.menu-live-bg)
            fill: #fff

      &.active
        background: var(--subHeader_activeBg)
        color: rgb(var(--thirdaryColor))
        position: sticky
        left: 0
        right: 0
        z-index: 1
        box-shadow: 0 -4px 0 1px var(--body_bg)

        :global(.svg-icon)
          path, :global(.menu-live-bg)
            fill: rgb(var(--thirdaryColor))

  + *
    --subHeader_height: 49px

.new, .live, .hot, .top, .popular
  min-width: 20px
  padding-left: 4px
  padding-right: 4px
  height: 14px
  font-size: 10px
  @include bold
  line-height: 10px
  @include flex
  justify-content: center
  border-radius: 2px
  background: var(--tagBg)
  position: absolute
  top: -3px
  text-transform: uppercase
  > span
    @include flex
    svg
      width: auto
      height: 12px
      path
        fill: #fff
  &::after
    content: ""
    position: absolute
    bottom: -3px
    left: 50%
    transform: translateX(-50%)
    width: 0
    height: 0
    border-style: solid
    border-width: 4px 4px 0px 4px
    border-color: var(--tagBg) transparent transparent transparent

.live
  padding-bottom: 1px
  --tagBg: #DA3A38
  color: #fff

.new
  padding-bottom: 1px
  --tagBg: #FFD801
  color: #161616

.hot
  --tagBg: #CC3E3E

.top
  --tagBg: #5bce60

.popular
  --tagBg: #0f72e5

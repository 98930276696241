@use "mixins" as *
.siteNotificationsModal
  top: 0
  height: 100%
  z-index: 1001

.siteNotificationsModal :global(.modal)
  height: auto
  max-width: 380px
  max-height: 60%
  box-shadow: none
  background: transparent
  border-radius: 15px
  display: flex
  overflow: visible
  > i
    background: var(--notification_closeBg)
    border-radius: 0 15px 0 8px
    right: 0
    top: 0

.siteNotifications
  width: 100%
  display: flex
  flex-direction: column
  border-radius: 15px
  box-shadow: 0 0 50px var(--body_bg)
  overflow: hidden
  background: var(--notification_bg)

.header
  display: flex
  align-items: flex-end
  justify-content: center
  background: var(--icon-notification-bg) no-repeat top center
  min-height: 56px

  > a
    background: var(--notification_contentBg)
    @include flex
    border: 2px solid rgb(var(--particularColor))
    border-bottom: 0
    border-top-left-radius: 12px
    border-top-right-radius: 12px
    height: 34px
    padding: 6px 25px 0
    position: relative
    bottom: 8px
    z-index: 2
    box-shadow: 0px -3px 6px rgba(0,0,0,.25)
    &::after
      content: ""
      width: 100%
      height: 2px
      left: 0
      position: absolute
      bottom: -2px
      background: var(--notification_contentBg)

.title
  @include bold
  font-size: 18px
  text-transform: uppercase
  margin-bottom: 14px
  padding: 0 10px

.content
  background: var(--notification_contentBg)
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5)
  border-radius: 15px
  border-top: 2px solid rgb(var(--particularColor))
  padding: 20px 10px 10px
  width: 100%
  color: #fff
  position: relative
  max-width: 380px
  overflow: hidden
  margin: -8px auto 0
  z-index: 1
  display: flex
  flex-direction: column

  & :global
    .slick-slider
      display: flex
      flex-direction: column
      overflow: hidden

    .slick-list
      overflow-y: auto
      margin-bottom: 10px
      &::-webkit-scrollbar-thumb,
      &::-webkit-scrollbar-thumb,
      &::-webkit-scrollbar-thumb
        background-color: rgba(255,255,255, .2) !important
        border: 3px solid var(--notification_contentBg) !important
      .slick-slide img
        margin-bottom: 14px !important
        max-width: 100%
        max-height: 124px
        border-radius: 8px

    .slick-dots
      position: static
      margin: 4px 0

      li
        margin: 0

        button:before
          color: var(--notification_dotBg)
          opacity: 1
          font-size: 13px
          line-height: 22px

        &.slick-active button:before
          color: rgb(var(--particularColor))

.item
  display: inline-flex !important
  min-height: 120px
  padding: 0 10px

  p
    font-size: 14px
    line-height: 20px
